import { render, staticRenderFns } from "./sxgk.vue?vue&type=template&id=4a9cff52&scoped=true&"
import script from "./sxgk.vue?vue&type=script&lang=js&"
export * from "./sxgk.vue?vue&type=script&lang=js&"
import style0 from "./sxgk.vue?vue&type=style&index=0&id=4a9cff52&prod&scoped=scoped&lang=css&"
import style1 from "./sxgk.vue?vue&type=style&index=1&id=4a9cff52&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a9cff52",
  null
  
)

export default component.exports